import { Component, PropsWithChildren } from 'react';
import classNames from 'classnames';
import Icon from 'mycs/shared/components/Icon/Icon';
import Button from 'mycs/shared/components/Button/Button';
import styles from './OptionsMenu.scss';
import Tooltip from 'mycs/shared/components/Tooltip/Tooltip';
import { useDevice } from 'mycs/router/DeviceContext';

export enum OptionsMenuMode {
  SingleLine = 'singleLine',
  MultiLine = 'multiLine',
}

export enum OptionsMenuSizeLabel {
  ExtraSmall = 'extraSmall',
  Small = 'small',
  Medium = 'medium',
  ExtraMedium = 'extraMedium',
  Large = 'large',
  ExtraLarge = 'extraLarge',
  FullScreen = 'fullScreen',
}

export enum OptionsMenuSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

type Props = PropsWithChildren<{
  title?: string;
  pillbox?: JSX.Element | null;
  showUndo?: boolean;
  onUndo?(): void;
  mode?: OptionsMenuMode;
  buttons?: JSX.Element[] | null;
  isOpen?: boolean;
  onChangeOpen?(isOpen: boolean): void;
  onUpdateSize?: (
    size: OptionsMenuSizeLabel | null,
    hasTabs: boolean,
    hasButtons: boolean
  ) => void;
  showHelpTooltip?: boolean;
  onToggleHelpTooltip?(): void;
  isLargerOnMobile?: boolean;
}>;

type State = {
  size: OptionsMenuSize;
  scrollableStyles: { maxHeight?: number };
  isPrevFullScreen: boolean;
};

export default function OptionsMenu(props: Props) {
  const { isMobile } = useDevice();

  return <InnerOptionsMenu {...props} isMobile={isMobile} />;
}

type InnerProps = Props & {
  isMobile: boolean;
};

export class InnerOptionsMenu extends Component<InnerProps, State> {
  scrollableContainer: HTMLDivElement | null = null;

  static defaultProps = {
    title: '',
    showUndo: false,
    onUndo: () => null,
    showHelpTooltip: false,
    onToggleHelpTooltip: () => null,
    isLargerOnMobile: false,
  };

  constructor(props: InnerProps) {
    super(props);

    const size =
      props.mode === OptionsMenuMode.SingleLine
        ? OptionsMenuSize.Large
        : OptionsMenuSize.Small;

    this.state = {
      size,
      scrollableStyles: {},
      isPrevFullScreen: false,
    };

    // Notify the parent of the menu size change
    if (props.onUpdateSize) {
      props.onUpdateSize(
        this.getSizeLabel(size),
        this.hasTabs(props),
        this.hasButtons(props)
      );
    }
  }

  hasTabs = (props: Props) => {
    const { pillbox } = props;
    return !(pillbox === null || pillbox === undefined);
  };

  hasButtons = (props: Props) => {
    const { buttons } = props;
    return !(buttons === null || buttons === undefined);
  };

  getSizeLabel = (size: OptionsMenuSize): OptionsMenuSizeLabel | null => {
    const { mode, isOpen, isLargerOnMobile } = this.props;
    let sizeLabel = null;
    if (size === OptionsMenuSize.Small && mode === OptionsMenuMode.SingleLine) {
      sizeLabel = OptionsMenuSizeLabel.ExtraSmall;
    }
    if (size === OptionsMenuSize.Small && mode === OptionsMenuMode.MultiLine) {
      sizeLabel = OptionsMenuSizeLabel.Small;
    }
    if (size === OptionsMenuSize.Medium) {
      sizeLabel = OptionsMenuSizeLabel.Medium;
      if (isLargerOnMobile) {
        sizeLabel = OptionsMenuSizeLabel.ExtraMedium;
      }
    }
    if (size === OptionsMenuSize.Large && mode === OptionsMenuMode.SingleLine) {
      sizeLabel = OptionsMenuSizeLabel.Large;
      if (isLargerOnMobile) {
        sizeLabel = OptionsMenuSizeLabel.ExtraLarge;
      }
    }
    if (
      isOpen ||
      (size === OptionsMenuSize.Large && mode === OptionsMenuMode.MultiLine)
    ) {
      sizeLabel = OptionsMenuSizeLabel.FullScreen;
    }
    return sizeLabel;
  };

  scrollToTop = () => {
    setTimeout(() => {
      if (this.scrollableContainer) {
        this.scrollableContainer.scrollTop = 0;
      }
    }, 1);
  };

  onHeaderClick = () => {
    const { isOpen, onChangeOpen, onUpdateSize } = this.props;
    const { size } = this.state;

    // Override the default click behaviour of the menu
    if (onChangeOpen) {
      onChangeOpen(!isOpen);
      return;
    }

    if (size === OptionsMenuSize.Large) {
      this.setState({ size: OptionsMenuSize.Small });
      // Reset the scroll position when closing the menu
      this.scrollToTop();
      // Notify the parent of the menu size change
      if (onUpdateSize) {
        onUpdateSize(
          this.getSizeLabel(OptionsMenuSize.Small),
          this.hasTabs(this.props),
          this.hasButtons(this.props)
        );
      }
    }
  };

  onMenuClick = () => {
    const { isMobile, onChangeOpen, onUpdateSize } = this.props;
    const { size } = this.state;

    // Override the default click behaviour of the menu
    if (onChangeOpen) return;

    if (
      (isMobile && size === OptionsMenuSize.Small) ||
      (!isMobile && size === OptionsMenuSize.Medium)
    ) {
      this.setState({ size: OptionsMenuSize.Large });
      // Notify the parent of the menu size change
      if (onUpdateSize) {
        onUpdateSize(
          this.getSizeLabel(OptionsMenuSize.Large),
          this.hasTabs(this.props),
          this.hasButtons(this.props)
        );
      }
    }
  };

  onMenuMouseOver = () => {
    const { isMobile, onChangeOpen } = this.props;
    const { size } = this.state;

    // Override the default hover behaviour of the menu if onChangeOpen is defined
    if (isMobile || onChangeOpen) return;

    if (size === OptionsMenuSize.Small) {
      this.setState({ size: OptionsMenuSize.Medium });
    }
  };

  onMenuMouseLeave = () => {
    const { isMobile, onChangeOpen } = this.props;
    const { size } = this.state;

    // Override the default hover behaviour of the menu if onChangeOpen is defined
    if (isMobile || onChangeOpen) return;

    if (size === OptionsMenuSize.Medium) {
      this.setState({ size: OptionsMenuSize.Small });
    }
  };

  onButtonsMouseOver = (event: any) => {
    const { isMobile } = this.props;
    if (isMobile) return;
    event.stopPropagation();
  };

  onUndo = (event: any) => {
    if (this.props.onUndo) {
      this.props.onUndo();
      event.stopPropagation();
    }
  };

  componentDidMount() {
    this.setState({ scrollableStyles: { maxHeight: window.innerHeight } });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.mode !== prevProps.mode) {
      const size =
        this.props.mode === OptionsMenuMode.SingleLine
          ? OptionsMenuSize.Large
          : OptionsMenuSize.Small;
      this.setState({
        size,
        isPrevFullScreen: Boolean(
          prevState.size === OptionsMenuSize.Large &&
            prevProps.mode === OptionsMenuMode.MultiLine
        ),
      });

      // Notify the parent of the menu size change
      if (this.props.onUpdateSize) {
        this.props.onUpdateSize(
          this.getSizeLabel(size),
          this.hasTabs(this.props),
          this.hasButtons(this.props)
        );
      }
    }

    if (this.props.buttons !== prevProps.buttons) {
      // Notify the parent of the buttons change
      if (this.props.onUpdateSize) {
        this.props.onUpdateSize(
          this.getSizeLabel(prevState.size),
          this.hasTabs(this.props),
          this.hasButtons(this.props)
        );
      }
    }

    if (this.state.size !== prevState.size) {
      this.setState({
        isPrevFullScreen: Boolean(
          prevState.size === OptionsMenuSize.Large &&
            this.props.mode === OptionsMenuMode.MultiLine
        ),
      });
    }
  }

  render() {
    const {
      children,
      title,
      pillbox,
      showUndo,
      buttons,
      isOpen,
      showHelpTooltip,
      onToggleHelpTooltip,
    } = this.props;
    const { size, scrollableStyles, isPrevFullScreen } = this.state;
    const sizeLabel = this.getSizeLabel(size);
    const hasTabs = this.hasTabs(this.props);

    return (
      <div
        className={classNames(styles.wrapper, {
          [styles.showUndo]: showUndo,
          [styles[sizeLabel!]]: sizeLabel !== null,
          [styles.fromFullScreen]: isPrevFullScreen,
          [styles.withoutTabs]: !hasTabs,
          [styles.hidden]: isOpen !== undefined && !isOpen,
        })}
        onMouseOver={this.onMenuMouseOver}
        onMouseLeave={this.onMenuMouseLeave}
        onClick={this.onMenuClick}
      >
        {showHelpTooltip && (
          <Tooltip
            hint
            content="help-menu"
            placement="top"
            onClose={onToggleHelpTooltip}
            show={showHelpTooltip}
            clickOutsideWhiteList={[
              '[class^="ConfiguratorTopMenu__menuActionButton"]',
            ]}
          />
        )}

        {buttons && (
          <div
            className={styles.buttonsContainer}
            onMouseOver={this.onButtonsMouseOver}
          >
            {buttons.map((button, i) => {
              return <div key={i}>{button}</div>;
            })}
          </div>
        )}

        <div className={styles.container}>
          <Button
            className={styles.header}
            isFullWidth
            trackLabel={title}
            onClick={this.onHeaderClick}
          >
            <div className={styles.undo}>
              <Tooltip
                hint
                content="help-menu-undo"
                placement="bottom"
                onClose={onToggleHelpTooltip}
                show={showHelpTooltip! && showUndo!}
                clickOutsideWhiteList={[
                  '[class^="ConfiguratorTopMenu__menuActionButton"]',
                ]}
              >
                <Icon iconName="configurator/back" onClick={this.onUndo} />
              </Tooltip>
            </div>

            <div className={styles.arrow}>
              <Icon iconName="general/arrow-chevron-up" />
            </div>

            <span>{title}</span>
          </Button>

          {pillbox && <div className={styles.pillbox}>{pillbox}</div>}

          <div
            ref={(node) => (this.scrollableContainer = node)}
            className={styles.scrollableContainer}
            style={scrollableStyles}
          >
            {children}
          </div>
        </div>
      </div>
    );
  }
}
